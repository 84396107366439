import './Spinner.css';

/*
Loading Page Component
*/

function Spinner() {
  return (
      <div className='spinner'></div>
  );
}

export default Spinner;