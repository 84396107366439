import './bottom.css'
import React from 'react';




const Bottom = () => {


  return (
    <div className='bottom'>
      <div className='bottom-section'>
        <div className='bottom-selection'>
          Mathieu Huet  |  mathieuhuet@live.ca  |  2023-2024
        </div>
      </div>
    </div>
  )
}

export default Bottom;